<template>
  <div>
    <estorno-saldo-conta-corrente-filtros
        ref="filtros"
        v-model="abrirFiltro"
        @RelatorioIntegracaoProvisao__AplicaFiltros="aplicarFiltros"/>

    <estorno-saldo-conta-corrente-tabela
        ref="tabela"/>
  </div>
</template>
<script>
import EstornoSaldoContaCorrenteFiltros from './EstornoSaldoContaCorrenteFiltros';
import EstornoSaldoContaCorrenteTabela from './EstornoSaldoContaCorrenteTabela';

export default {
  components: {
    EstornoSaldoContaCorrenteFiltros,
    EstornoSaldoContaCorrenteTabela,
  },
  computed: {
  },
  data() {
    return {
      abrirFiltro: false,
      filtros: Object,
    };
  },
  methods: {
    aplicarFiltros(filtros) {
      this.$refs.tabela.filtrar(filtros);
      this.filtros = filtros;
    },
  },
};
</script>
