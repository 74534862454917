<template>
<div>
  <v-card>
    <div class="pa-5 text-right" v-if="validaRoleConfirmar">
    <v-btn @click="validaRealizarEstorno"
           :disabled="estornos.filter(estorno=>estorno.status === 'PENDENTE').length<=0"
           color="info"
    >
      {{$t('label.confirmar_estorno')}}</v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="estornos"
      :options.sync="pagination"
      v-model="estornosSelecionados"
      show-select
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      @toggle-select-all="selectAllToggle"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
            :value="item.status !== 'AGUARDANDO_ESTORNO' && item.status !== 'REALIZADO' && isSelected"
            :readonly="item.status === 'REALIZADO' || item.status==='AGUARDANDO_ESTORNO'"
            :disabled="item.status === 'REALIZADO'|| item.status==='AGUARDANDO_ESTORNO'"
            @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <template nowrap v-slot:item.valor="{ item }">
        <div style="white-space: nowrap">
          {{ formataCampoValor(item) }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div style="white-space: nowrap">
          {{ $tc(`status_estorno.${item.status}`, 1) }}
        </div>
      </template>
    </v-data-table>
  </v-card>

  <confirm
    ref="modalConfirmacaoEstornoSelecionados"
    :message="$t('message.estorno_conta_corrente_selecionados')"
    @agree="realizarEstorno"
    @disagree="$refs.modalConfirmacaoEstornoSelecionados.close()">
  </confirm>

  <confirm
      ref="modalConfirmacaoEstornoIntegral"
      :message="$t('message.estorno_conta_corrente_integral')"
      @agree="realizarEstorno"
      @disagree="$refs.modalConfirmacaoEstornoIntegral.close()">
  </confirm>

</div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  getMoney,
  getPercent,
} from '@/produto/common/functions/helpers';
import Confirm from '@/produto/shared-components/vuetify/dialog/Confirm';
import { buscarEstornoContaCorrenteSuzano, inserirFilaEstornoContaCorrenteSuzano } from '@/common/resources/estorno-saldo-conta-corrente';
import EstornoSaldoContaCorrenteFiltrosCampos from '@/spa/estorno-conta-corrente/EstornoSaldoContaCorrenteFiltrosCampos';

export default {
  name: 'EstornoSaldoContaCorrenteFiltros',
  mixins: [
    EstornoSaldoContaCorrenteFiltrosCampos,
  ],
  data() {
    return {
      estornos: [],
      estornosSelecionados: [],
      estornosPendentesSelecionados: [],
      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      lastPagination: {},
      filtros: null,
    };
  },
  components: {
    Confirm,
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        if (this.filtros === null) {
          return;
        }
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    validaRoleConfirmar() {
      if (!this.getAllRoles
            || !this.getAllRoles.filter((el) => el.startsWith('ESTORNO_CONFIRMAR')).length) {
        return false;
      }
      return true;
    },
    headers() {
      const headers = [];
      headers.push({
        text: this.$tc('label.orcamento', 1), width: '14%', value: 'nomOrcamento', sortable: false,
      });
      headers.push({
        text: this.$tc('label.periodo', 1), width: '8%', value: 'periodo', sortable: false,
      });
      headers.push({
        text: this.$tc('label.linha_de_investimento', 1), width: '14%', value: 'linhaInvest', sortable: false,
      });
      headers.push({
        text: this.$tc('label.customer_regional', 1), width: '14%', value: 'customerRegional', sortable: false,
      });
      headers.push({
        text: `${this.$tc('label.hierarquia', 1)} 1`, width: '6%', value: 'hierarquia1', sortable: false,
      });
      headers.push({
        text: `${this.$tc('label.hierarquia', 1)} 2`, width: '6%', value: 'hierarquia2', sortable: false,
      });
      headers.push({
        text: `${this.$tc('label.hierarquia', 1)} 3`, width: '10%', value: 'hierarquia3', sortable: false,
      });
      headers.push({
        text: this.$tc('label.produto', 1), width: '12%', value: 'produto', sortable: false,
      });
      headers.push({
        text: this.$tc('label.valor_estorno', 1), width: '8%', value: 'valor', sortable: false,
      });
      headers.push({
        text: this.$tc('label.status', 1), width: '5%', value: 'status', sortable: false,
      });
      headers.push({
        text: this.$tc('label.data_estorno', 1), width: '5%', value: 'dataEstornoFormatado', sortable: false,
      });
      return headers;
    },
  },
  methods: {
    getMoney,
    getPercent,
    formataCampoValor(item) {
      if (item.indPercentual) {
        return this.getPercent(item.valor);
      }
      return this.getMoney(item.valor);
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    selectAllToggle() {
      if (this.estornosSelecionados.length > 0) {
        this.estornosSelecionados = [];
      }
    },
    filtrar(filtrosAplicados = null) {
      if (filtrosAplicados) {
        this.filtros = filtrosAplicados;
        this.pagination.page = 1;
      }

      const parametros = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      buscarEstornoContaCorrenteSuzano(parametros, this.$resource).then((res) => {
        this.totalPage = res.data.quantidadeRegistrosPagina;
        this.estornos = res.data.resposta;
        const estornosRealizados = this.estornos.filter((selecionados) => selecionados.status === 'REALIZADO' || selecionados.status === 'AGUARDANDO_ESTORNO');
        estornosRealizados.forEach((estorno) => {
          estorno.disabled = true;
        });
      });
    },
    validaRealizarEstorno() {
      if (this.estornosSelecionados.length > 0) {
        const estornosPendentes = this.estornosSelecionados.filter((selecionados) => selecionados.status === 'PENDENTE');
        if (estornosPendentes.length > 0) {
          this.estornosPendentesSelecionados = estornosPendentes;
          this.$refs.modalConfirmacaoEstornoSelecionados.open();
        }
      } else {
        this.estornosPendentesSelecionados = [];
        this.$refs.modalConfirmacaoEstornoIntegral.open();
      }
    },
    realizarEstorno() {
      const parametros = {
        ...this.filtros,
        idsEstornos: this.estornosPendentesSelecionados.map((estorno) => estorno.id),
      };
      inserirFilaEstornoContaCorrenteSuzano(parametros, this.$resource).then(() => {
        this.estornosSelecionados = [];
        this.filtrar();
      });
    },
  },
};
</script>
