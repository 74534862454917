import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('orcamento', 'ajuste_verba_suzano');

const ajusteVerbaActions = {};

export default (resource) => resource(`${basePath}`, {}, ajusteVerbaActions);

export const listarPeriodoOrcamentario = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'periodo_orcamentario', parametros).doGet();
export const listarLinhaInvestimento = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'linha_investimento', parametros).doGet();
