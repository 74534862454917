import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('orcamento', 'estorno_conta_corrente');

const suzanoEstornoSaldoActions = {};

export const buscarEstornoContaCorrenteSuzano = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar', parametros).doGet();

export const inserirFilaEstornoContaCorrenteSuzano = (parametros, resource) => resourceBuilder.buildPOST(resource, basePath, 'inserirFilaEstornoContaCorrente', parametros).doPost();

export const countEstornoContaCorrenteSuzano = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'count-estorno', parametros).doGet();

export default (resource) => resource(`${basePath}`, {}, suzanoEstornoSaldoActions);
