<template>
  <v-form ref="form" autocomplete="off">
    <v-card style="margin-bottom:1em" class="RelatorioIntegracaoProvisao elevation-0">
      <v-card-title>
        <span class="title">{{ $tc('title.filtro', 2) }}</span>

        <v-spacer></v-spacer>
        <icon-filtro @click="toggleFiltros" :filtros="filtrosEstorno" />
      </v-card-title>

      <v-container fluid grid-list-md v-show="filtrosAbertos">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="filtrosEstorno"
          ref="container">
        </metadados-container-layout>
      </v-container>

      <v-card-actions v-show="filtrosAbertos">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.listar_estorno_saldo')}}</v-btn>
        <v-btn @click="aplicarFiltrosExportacao"
               color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import IconFiltro from '@/produto/shared-components/filtros/IconFiltro';
import { countEstornoContaCorrenteSuzano } from '@/common/resources/estorno-saldo-conta-corrente';
import { removeEmptyProperties } from '../../produto/common/functions/helpers';
import exportacao from '../../produto/common/functions/exportacao';
import EstornoSaldoContaCorrenteFiltrosCampos from './EstornoSaldoContaCorrenteFiltrosCampos';

export default {
  name: 'RelatorioIntegracaoProvisao',
  mixins: [
    EstornoSaldoContaCorrenteFiltrosCampos,
  ],
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  data() {
    return {
      ordenacaoCampos: [
        'tipo_de_verba',
        'orcamento',
        'periodo_orcamentario',
        'linha_de_investimento',
        'status',
        'dta_estorno_inicial',
        'dta_estorno_final',
      ],
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      filtrosAbertos: true,
      filtrosFixados: false,
      filtrosEstorno: {},
    };
  },
  methods: {
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    resetaFiltros() {
      this.filtrosEstorno = {};
    },
    aplicarFiltrosExportacao() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };
      if (!params) {
        return;
      }
      this.filtros = {
        ...params,
      };
      let quantidadeRegistros = 0;
      countEstornoContaCorrenteSuzano(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data.count;
          if (quantidadeRegistros > 0) {
            this.requestExportacao(this.filtros, 'estorno_conta_corrente', 'orcamento');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'liquidacao') {
      const filtroTratado = removeEmptyProperties(filtros);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      this.$emit('RelatorioIntegracaoProvisao__AplicaFiltros', params);
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.dta_estorno_inicial || filtrosAplicados.dta_estorno_final) {
        const dtInicio = this.moment(filtrosAplicados.dta_estorno_inicial, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.dta_estorno_final, 'YYYY-MM-DD');

        if (filtrosAplicados.dta_estorno_inicial && !filtrosAplicados.dta_estorno_final) {
          this.$toast(this.$t('errors.dta_estorno_final_obrigatorio'));
          return false;
        }

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('errors.dta_estorno_inicial_menor_final'));
          return false;
        }
        return true;
      }
      return true;
    },
  },
};
</script>
<style>
  .RelatorioIntegracaoProvisao__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioIntegracaoProvisao__Opcoes div.v-input__slot,
  .RelatorioIntegracaoProvisao__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioIntegracaoProvisao__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioIntegracaoProvisao__Opcoes label {
    font-size: 14px;
  }
</style>
