import { listarPeriodoOrcamentario, listarLinhaInvestimento } from '@/common/resources/ajuste-verba-suzano';

export default {
  data() {
    return {
      ajusteVerbaResource: this.$api.ajusteVerba(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'tipo_de_verba',
            nomCampoId: 'tipo_de_verba',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => this.ajusteVerbaResource
                .listarTipoVerba({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status_estorno',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { nome: `${this.$tc('status_estorno.AGUARDANDO_ESTORNO', 1)}`, id: 'AGUARDANDO_ESTORNO' },
                  { nome: `${this.$tc('status_estorno.PENDENTE', 1)}`, id: 'PENDENTE' },
                  { nome: `${this.$tc('status_estorno.REALIZADO', 1)}`, id: 'REALIZADO' },
                ],
              })),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'orcamento',
            nomCampoId: 'orcamento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const idTipoVerba = this.$refs.container.getValoresCamposPadrao().tipo_de_verba;
                return this.ajusteVerbaResource
                  .listarOrcamento({ autocomplete, idTipoVerba });
              },
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'periodo_orcamentario',
            nomCampoId: 'periodo_orcamentario',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependencia: 'periodo',
            },
            async: {
              fetchFunction: (autocomplete) => {
                const idTipoVerba = this.$refs.container.getValoresCamposPadrao().tipo_de_verba;
                const idsOrcamento = this.$refs.container.getValoresCamposPadrao().orcamento;

                return listarPeriodoOrcamentario({ autocomplete, idTipoVerba, idsOrcamento }, this.$resource);
              },
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'linha_de_investimento',
            nomCampoId: 'tipo_linha_orcamento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const idTipoVerba = this.$refs.container.getValoresCamposPadrao().tipo_de_verba;
                const idsOrcamento = this.$refs.container.getValoresCamposPadrao().orcamento;
                const idsPeriodoOrcamentario = this.$refs.container.getValoresCamposPadrao().periodo_orcamentario;

                return listarLinhaInvestimento({
                  autocomplete, idTipoVerba, idsOrcamento, idsPeriodoOrcamentario,
                }, this.$resource);
              },
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'dta_estorno_inicial',
            nomCampo: this.$t('label.dta_estorno_inicial'),
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'dta_estorno_final',
            nomCampo: this.$t('label.dta_estorno_final'),
            tipoCampo: 'DATA',
          },
        ],
      },
    };
  },
};
